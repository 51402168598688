export default [
  {
    id: 3,
    imgSrc: '/images/meet/Marc.webp',
    alt: 'Spiritlabs',
    fullName: 'Marc Amez - Droz',
    position: 'Advisory Board',
    linkedin: 'https://www.linkedin.com/in/marcamezdroz/?originalSubdomain=hk',
  },
  {
    id: 2,
    imgSrc: '/images/meet/Rafal.webp',
    alt: 'Spiritlabs',
    fullName: 'Rafal Czerniawski',
    position: 'Advisory Board',
    linkedin: 'https://www.linkedin.com/in/rafalcz/',
  },
  {
    id: 1,
    imgSrc: '/images/meet/director.webp',
    alt: 'Spiritlabs',
    fullName: 'Minh Tran',
    position: 'Managing Director',
    linkedin: 'https://www.linkedin.com/in/minh-tran-dinh-82727919',
  },
  {
    id: 4,
    imgSrc: '/images/meet/HR.webp',
    alt: 'Spiritlabs',
    fullName: 'Holly Le',
    position: 'Head of HR and Finance',
    linkedin: 'https://www.linkedin.com/in/holly-le-b0b23638/',
  },
  {
    id: 5,
    imgSrc: '/images/meet/team-manager.webp',
    alt: 'Spiritlabs',
    fullName: 'Huy Pham',
    position: 'Team Manager',
    linkedin: '/',
  },
  {
    id: 6,
    imgSrc: '/images/meet/marketing-leader.webp',
    alt: 'Spiritlabs',
    fullName: 'Kylie Nguyen',
    position: 'Marketing Lead',
    linkedin: 'https://www.linkedin.com/in/duyen-nguyen15/',
  },
  // {
  //   id: 8,
  //   imgSrc: '/images/meet/fe-leader.webp',
  //   alt: 'Spiritlabs',
  //   fullName: 'Van Minh Tran',
  //   position: 'Frontend Team Lead',
  //   linkedin: 'https://www.linkedin.com/in/minh-van-tran/',
  // },
  {
    id: 9,
    imgSrc: '/images/meet/R&D-leader.webp',
    alt: 'Spiritlabs',
    fullName: 'Hieu Nguyen',
    position: 'R&D Team Lead',
    linkedin: 'https://www.linkedin.com/in/hieu-m-nguyen/',
  },
];
