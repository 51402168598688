import styled from '@emotion/styled';

import { breakPoints } from 'constant/breakPoints';
import PROFILE from './PROFILE';

function MeetTheTeam(): JSX.Element {
  return (
    <Wrap>
      <h4 className="text-center title-section">MEET THE TEAM</h4>

      <TeamGrid>
        {PROFILE.map((item, idx) => (
          <div className="team-item" key={idx}>
            <div className="team-item-padding">
              <img src={item.imgSrc} alt={item.alt} loading="lazy" />
            </div>
            <div className="team-item-profile">
              <a href={item.linkedin} target="_blank" rel="noreferrer">
                <img
                  className="linkedin-icon"
                  src="/images/app/linkedin-icon.png"
                  alt="spiritlabs linkedin"
                />

                <TeamTitle className="text-center">
                  <h6 className="typo1 font-semibold one-line">
                    {item.fullName}
                  </h6>
                  <p className="typo2">{item.position}</p>
                </TeamTitle>
              </a>
            </div>
          </div>
        ))}
      </TeamGrid>
    </Wrap>
  );
}

export default MeetTheTeam;

const Wrap = styled.section`
  padding: 40px 0 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`;

const TeamGrid = styled.div`
  margin-top: 47px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .team-item {
    position: relative;
    color: var(--white);
    overflow: hidden;
    min-width: 230px;
    max-width: 288px;
    width: 33%;

    .team-item-padding {
      position: relative;
      padding-bottom: 126%;

      img:not(.linkedin-icon) {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        z-index: 1;
        /* will-change: transform; */

        filter: gray; // IE6-9
        -webkit-filter: grayscale(1); // Google Chrome, Safari 6+ & Opera 15+
        filter: grayscale(1);
        transition: all 0.4s ease-out;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      img:not(.linkedin-icon) {
        height: 100%;
      }
    }

    &:hover {
      img:not(.linkedin-icon) {
        filter: grayscale(0);
        filter: none;
        transform: scale(1.15);
        -webkit-filter: grayscale(0);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 50.12%,
        rgba(0, 0, 0, 0.7) 91.89%
      );
      z-index: 2;
    }

    &-profile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 16px 20px;
      z-index: 3;

      h6 {
        margin: 0 0 4px;
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        img {
          height: 24px;
          width: 24px;
          margin-right: 20px;
          margin-bottom: 5px;
        }
      }
    }

    @media screen and (max-width: ${breakPoints.tabletS - 1}px) {
      max-width: 230px;
      min-width: initial;
      width: 50%;

      &-profile {
        padding: 10px 10px 16px 10px;

        a img {
          margin-right: 7px;
        }
      }
    }

    @media screen and (min-width: ${breakPoints.desktopXM}px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 288px));
    }
  }
`;

const TeamTitle = styled.div`
  flex: 1;
  transform: translateX(-24px);

  @media screen and (max-width: ${breakPoints.tabletS - 1}px) {
    transform: none;
  }
`;
